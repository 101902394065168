@import './fonts/fonts.css';
@import './colors.css';

* {
  box-sizing: border-box !important;
  text-decoration: none;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  position: relative;
}

body {
  background-color: #181a1f;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  width: 100%;
  margin: 0;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.cursor-pointer {
  cursor: pointer !important;
}

.fade-enter {
  opacity: 0 !important;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1 !important;
  transform: translate(0, 0);
  transition: opacity 350ms ease-out, transform 350ms ease;
}
.fade-exit {
  opacity: 1 !important;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0 !important;
  transform: translate(0, 30px);
  transition: opacity 200ms ease-out, transform 350ms ease;
}

.text-overflow-ellipses {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edit-address-cell {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  padding: 10px;
  margin-left: -10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.col-with-border {
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 10px 15px;
}
