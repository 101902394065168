/* TODO: italic fonts needed but were not available here: https://fonts.google.com/specimen/Inter?query=inter */
/* ExtraBold was also not available, but likely not needed */

@font-face {
  font-family: 'Inter';
  src: url('Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* @font-face {
  font-family: 'Inter';
  src: url('Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
} */

@font-face {
  font-family: 'Inter';
  src: url('Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
