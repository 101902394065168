:root {
  --grey-1: #a7a7a7;
  --grey-2: #c7c7c7;
  --grey-3: #f7f7f7;
  --info: #0275d8;
  --info-light: #44a7fd;
  --error: #dc3545;
  --text: #344767;
  --success: #28a745;
}
