.ant-steps-item-title {
  font-weight: bold !important;
  line-height: normal !important;
}

.ant-steps-item-content {
  user-select: none !important;
  min-height: 80px !important;
}

.ant-message-custom-content {
  max-width: 400px !important;
  overflow: hidden !important;
}

.ant-steps-item-icon {
  user-select: none !important;
}

.ant-enabled-step {
  cursor: pointer !important;
}

.ant-disabled-step {
  cursor: not-allowed !important;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-collapse-header-text {
  width: 85% !important;
  & a {
    pointer-events: auto !important;
  }
}

.ant-upload.ant-upload-select {
  width: 100% !important;
}

.ant-upload-list-item-container {
  width: 100% !important;
}

.ant-form-item-extra {
  margin-top: 7px !important;
}

.ant-upload-list-item-name {
  cursor: pointer !important;
  opacity: 1 !important;
  color: #1668dc !important;
}

.ant-upload-list-item-name:hover {
  cursor: pointer !important;
  opacity: 1 !important;
  color: #1668dc !important;
}

.ant-upload-list-item-progress {
  width: 88% !important;
  padding-top: 5px !important;
  transform: translateY(5px);
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #b5daff21 inset !important;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin-left: 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-layout-sider-trigger {
  background-color: #3c3e42 !important;
}

.wide-notification {
  width: 80vw !important;
  max-width: 1000px;
}

.ant-table-placeholder {
  background-color: transparent !important;
}

.eulith-table-row {
  cursor: pointer;
}

.ant-layout-sider-children {
  overflow: auto !important;
}

.ant-table-row-expand-icon-cell {
  background-color: transparent !important;
}

.antd-table-row-no-border-radius td {
  border-radius: 0 !important;
}
