.armor-steps {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}

.armor-steps-visible {
  opacity: 1;
  pointer-events: auto;
}
